import React from "react";
import "../CSS/Contact.css";

const Contact = () => {
  return (
    <div className="contact">

      <h1>Thanks for getting in touch 😁!!!</h1>
    </div>
  );
};

export default Contact;
